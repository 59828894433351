<template>
  <v-container fluid class="resources-page">
    <v-row>
      <v-col cols="auto" md="12" sm="12">
        <v-card>
          <v-card-text>
            <v-row class="d-flex align-center">
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('resourceOverviewPage.smsMessagesLeft') }}</span>
                <span class="d-block">{{ mainBannerData?.sms_total_remaining }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('resourceOverviewPage.flightsCheckLeft') }}</span>
                <span class="d-block">{{ mainBannerData?.flight_check_total_remaining }}</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn class="primary mr-2" elevation="2" text @click="buyMoreSms">
                  {{ $t('buttons.buyMoreSms') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div id="resources-page-step-0"></div>
      </v-col>

      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <div :id="window.width < 600 ? 'resources-page-step-1' : ''"></div>
        <v-data-table
          :footer-props="{
            pageText: `{0}-{1} ${$t('tables.of')} {2}`,
            itemsPerPageText: $t('tables.resourcesUsedByPage'),
            itemsPerPageOptions: [5, 10, 20, 50, 100],
          }"
          :headers="resourcesHeaders"
          :disable-sort="tourActive"
          :class="tourActive ? 'no-pointers' : ''"
          :no-data-text="$t('tables.noDataText')"
          :no-results-text="$t('tables.noDataText')"
          :items="drivingInNext3Days"
          :search="searchString"
          class="elevation-1"
          :items-per-page="10"
          multi-sort>
          <template v-slot:item.action_type="{ item }">
            {{ getTranslatedActionType(item.action_type) }}
          </template>
          <template v-slot:item.body="{ item }">
            {{ JSON.parse(item.body).message }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <template>
                <v-toolbar-title>
                  {{ $t('tables.resourcesUsed') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  id="resources-page-step-2"
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <p :id="window.width > 599 ? 'resources-page-step-1' : ''" class="first-step"></p>

                <v-spacer />
              </template>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <div class="tourStyle">
      <v-tour name="resourcesTour" :steps="stepsTour" :callbacks="myCallbacks" :options="options"></v-tour>
    </div>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { resourcesHeaders } from '@/mixins/data-table-headers';
import { formatDateTime } from '@/utils/formatDate';
import { getAllTourSteps, resourcesPageSteps } from '@/mixins/onboarding-tutorial-steps';
import { mapState } from 'vuex';

export default {
  name: 'ResourceOverviewPage',
  props: [],
  components: {},
  data() {
    return {
      searchString: '',
      mainBannerData: { sms_total_remaining: 0, flight_check_total_remaining: 0 },
      drivingInNext3Days: [],
      myCallbacks: {
        onStop: this.onComplete,
        onNextStep: this.onNextStep,
        onSkip: this.onSkip,
      },
      steps: [],
      visitedStepsArray: [],
      stepsTour: [],
      window: {
        width: 0,
      },
      options: {
        labels: {
          buttonSkip: i18n.t('buttons.skip'),
          buttonPrevious: '<< ' + i18n.t('buttons.prev'),
          buttonNext: i18n.t('buttons.next') + ' >>',
          buttonStop: i18n.t('buttons.finish'),
        },
        highlight: true,
      },
    };
  },
  created() {
    this.getResourceData();
    this.getAuditData();

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
    ...mapState('onboardingTutorial', ['tourActive']),
    resourcesHeaders() {
      return resourcesHeaders(i18n);
    },
  },
  methods: {
    async getResourceData() {
      await this.$store.dispatch('resources/getResourceData').then((res) => {
        this.mainBannerData = res.data;
      });
    },
    async getAuditData() {
      await this.$store.dispatch('resources/getAuditData').then((res) => {
        this.drivingInNext3Days = res.data.map((item) => {
          item.created_at = formatDateTime(item.created_at);
          return item;
        });
      });

      this.setStepsAndStartTour();
    },
    getTranslatedActionType(actionType) {
      switch (actionType) {
        case 'sms':
          return i18n.t('resourceOverviewPage.sms');
        case 'flight_check':
          return i18n.t('resourceOverviewPage.flightCheck');
        default:
          return i18n.t('resourceOverviewPage.sms');
      }
    },
    buyMoreSms() {
      this.$store
        .dispatch('resources/generateMoreSmsLink')
        .then((res) => {
          window.open(res.data);
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    async setStepsAndStartTour() {
      if (this.$store.state.auth.role !== 1 && !this.tourActive) {
        await this.setSteps();
        if (this.stepsTour?.length > 0) {
          this.startTour();
        }
      }
    },

    setSteps() {
      return new Promise((resolve) => {
        const visitedStepsParsed = JSON.parse(localStorage.getItem('LimoExpressUser'))?.user_tour_logs;

        if (visitedStepsParsed) {
          this.visitedStepsArray = visitedStepsParsed
            .filter((item) => item.component_id === '7')
            ?.map((item) => item.step_id);
          this.steps = resourcesPageSteps();
          if (this.visitedStepsArray?.length > 0) {
            this.stepsTour = this.steps.filter((step) => !this.visitedStepsArray.includes(step.target));
          } else {
            this.stepsTour = this.steps;
          }
        } else {
          this.stepsTour = [];
          let user = this.$store.getters['auth/user'];
          user.user_tour_logs = getAllTourSteps();

          this.$store.dispatch('auth/setUser', user);
          localStorage.setItem('LimoExpressUser', JSON.stringify(user));
        }

        resolve();
      });
    },

    startTour() {
      this.$tours['resourcesTour'].start();
      this.$store.dispatch('onboardingTutorial/toggleTour', true);
    },

    onComplete() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.setStepAsVisited(this.steps.pop()?.target?.split('-').pop());
    },

    onSkip() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);

      const body = {
        objects: this.steps.map((item) => ({
          component_id: '7',
          step_id: item.target,
        })),
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      const lastCharsOfTarget = this.steps.map((step) => step.target.slice(-1));
      lastCharsOfTarget.forEach((step) => this.updateStorage(step));
    },

    onNextStep(currentStep) {
      const currentStepId = this.stepsTour[currentStep]?.targetId;
      this.setStepAsVisited(currentStepId);
    },

    updateStorage(currentStep) {
      const newVisitedStep = {
        component_id: '7',
        step_id: `#resources-page-step-${currentStep}`,
      };

      let user = this.$store.getters['auth/user'];

      if (user) {
        if (user.user_tour_logs?.length > 0) {
          user.user_tour_logs.push(newVisitedStep);
        } else {
          user.user_tour_logs = [newVisitedStep];
        }
      }

      this.$store.dispatch('auth/setUser', user);
      localStorage.setItem('LimoExpressUser', JSON.stringify(user));
    },

    setStepAsVisited(step) {
      const body = {
        objects: [
          {
            component_id: '7',
            step_id: `#resources-page-step-${step}`,
          },
        ],
      };
      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      this.updateStorage(step);
    },

    handleResize() {
      this.window.width = window.innerWidth;
    },
  },
  beforeDestroy() {
    document.body.classList.remove('v-tour--active');
    this.$store.dispatch('onboardingTutorial/toggleTour', false);
  },
  watch: {
    tourActive() {
      this.setStepsAndStartTour();
    },
    '$store.state.onboardingTutorial.tourReset': {
      handler() {
        if (this.$store.state.onboardingTutorial.tourReset) {
          this.setStepsAndStartTour(); // Function to start your Vue Tour

          // Clear the tour reset flag to avoid repeat triggers
          this.$store.dispatch('onboardingTutorial/clearTourReset');
        }
      },
    },
  },
};
</script>

<style>
.resources-page {
  .no-pointers {
    pointer-events: none;
  }

  .v-step {
    inset: 0 0 auto -10px !important;
  }

  .first-step {
    margin-top: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
